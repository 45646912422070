import './slider.component.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';


function Slider(props) {
  let imgArray = [
    "fotografia_diapositiva-1.jpg","fotografia_diapositiva-2.jpg",
    "fotografia_diapositiva-3.jpg","fotografia_diapositiva-4.jpg",
    "fotografia_diapositiva-5.jpg","fotografia_diapositiva-6.jpg",
    "fotografia_diapositiva-7.jpg","fotografia_diapositiva-8.jpg",
    "fotografia_diapositiva-9.jpg"
  ];


 
  for(let i=0; i < props.extraPhotos.length; i++){
    imgArray.unshift(props.extraPhotos[i]);
  }
 
  return (
   
      <Carousel >
        {imgArray.map((img,i)=>(
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={require('../../assets/img/slider/' + img+'')}
              alt="slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    
  );
}

export default Slider;