import './container.component.scss';
import React, { useState,useEffect } from "react";
import Header from '../header/header.component';
import Footer from '../footer/footer.component';
import Content from '../content/content.component';
import PageNotFound from '../page-not-found/page-not-found.component';
import data from '../../sections.json';



function Container() {

  const [pageFound, setPageFound] = useState(false);
  const [familyIdx, setFamilyIdx] = useState(70);
  let url = window.location.pathname.slice(1,5);
  let families = [];


  useEffect(() => {
    getData()

    if(url.length > 0){
    getIndex();
    }
   
})

  function getData() {
    if(families.length === 0){
      data.map((data) => (
        families = data.families
      ));
    }
  }

  function getIndex() {
    families.forEach((element) => {
      if (element.id === url) {
        setFamilyIdx(families.indexOf(element));
        searchPage();
      }
    });

  }

  function searchPage() {

    if(familyIdx===70) {
      setPageFound(false);
    }
    else if ( isNaN(familyIdx) && familyIdx <= 60 ||  familyIdx >= 0 ) {
      setPageFound(true);
    }
  }
  
  return (
      <div>
        
        {
          !pageFound ?
            <div className="app-container">
              <PageNotFound></PageNotFound>
            </div>

          : <div className="app-container">
              <Header></Header>
              <Content familyId={familyIdx} ></Content>
              <Footer></Footer>
            </div>
        }
      </div>
  );

}

export default Container;
