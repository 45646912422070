import './page-not-found.component.scss';

function PageNotFound() {
  return (
    <div className="page-not-found">

  
      <h1>
      &#128552; 	&#128552; 	&#128552; 	&#128552; 	&#128552;  <br/>
        Ups, esta direccion es incorrecta. Vuelve a escanear el codigo 	
        <br/> &#128531;
      </h1>


    </div>
  );
}

export default PageNotFound;
