import './App.scss';
import Container from './components/container/container.component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';


library.add(fas);

function App() {
  return (
    <div className="App">
      <Container></Container>
    </div>
  );
}

export default App;
