import './footer.component.scss';

function Footer() {
  return (
    <div className="footer">
      <img src={require("../../assets/img/logotipo_beige.png")} className="footer__logo" alt={"Paola&Albert"} width="110px" />
      <div className="footer__info">
        <p className="footer__info-subtitle">
          CUALQUIER DUDA,
          NO  DUDES EN LLAMARNOS
        </p>
        <div className="footer__mobile-phones">
          <p>
            PAOLA
          </p>
          <p>
            +34 637 777 537
          </p>
          <p>
            ALBERT
          </p>
          <p>
            +34 676 618 453
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
