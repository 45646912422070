import './video-slider.component.scss';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';


function VideoSlider() {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);

  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);

  const handleClose2 = () => setShowModal2(false);
  const handleShow2 = () => setShowModal2(true);

  const handleClose3 = () => setShowModal3(false);
  const handleShow3 = () => setShowModal3(true);

  const handleClose4 = () => setShowModal4(false);
  const handleShow4 = () => setShowModal4(true);

  const handleClose5 = () => setShowModal5(false);
  const handleShow5 = () => setShowModal5(true);

  return (
    <div className="video-slider__wrapper">
      <div className="video-slider__header">
        <img className="video-slider__header-title" src={require('../../assets/img/logotipo-plus_blanco.png')} alt="PA Plus" />
        <div className="video-slider__header-subtitle">
          <p>CONTENIDO EXCLUSIVO</p>
        </div>
      </div>
      <div className="video-slider">
        <div className="video-slider__content">
            <div className="video-slider-item" onClick={handleShow1}>
              <div>
                <img className="video-slider-item__img" src={require('../../assets/img/la-pedida_poster.png')}  width="100%" alt="Video"/>
              </div>

              <div className="video-slider-item__details">
                <p className="video-slider-item__details-title">LA PEDIDA</p>
                <p className="video-slider-item__details-subtitle">3:55</p>
              </div>
            </div>

            <Modal centered show={showModal1} onHide={handleClose1}>
              <Modal.Body>
                <ReactPlayer url={require('../../assets/video/LA_PEDIDA.mp4')} controls loop width="100%" height="100%" config={{ file: { attributes: { controlsList:'nodownload' } }}}></ReactPlayer>
              </Modal.Body>
            </Modal>

            <div className="video-slider-item" onClick={handleShow2}>
              <div>
                <img className="video-slider-item__img" src={require('../../assets/img/el-novio_poster.png')}  width="100%" alt="Video"/>
              </div>

              <div className="video-slider-item__details">
                <p className="video-slider-item__details-title">EL NOVIO</p>
                <p className="video-slider-item__details-subtitle">1:23</p>
              </div>
            </div>

            <Modal centered show={showModal2} onHide={handleClose2}>
              <Modal.Body>
                <ReactPlayer url={require('../../assets/video/EL_NOVIO.mp4')} controls loop width="100%" height="100%" config={{ file: { attributes: { controlsList:'nodownload' } }}}></ReactPlayer>
              </Modal.Body>
            </Modal>

            <div className="video-slider-item" onClick={handleShow3}>
              <div>
                <img className="video-slider-item__img" src={require('../../assets/img/la-novia_poster.png')}  width="100%" alt="Video"/>
              </div>

              <div className="video-slider-item__details">
                <p className="video-slider-item__details-title">LA NOVIA</p>
                <p className="video-slider-item__details-subtitle">1:18</p>
              </div>
            </div>

            <Modal centered show={showModal3} onHide={handleClose3}>
              <Modal.Body>
                <ReactPlayer url={require('../../assets/video/LA_NOVIA.mp4')} controls loop width="100%" height="100%" config={{ file: { attributes: { controlsList:'nodownload' } }}}></ReactPlayer>
              </Modal.Body>
            </Modal>

            <div className="video-slider-item" onClick={handleShow4}>
              <div>
                <img className="video-slider-item__img" src={require('../../assets/img/el-lugar_poster.png')}  width="100%" alt="Video"/>
              </div>

              <div className="video-slider-item__details">
                <p className="video-slider-item__details-title">EL LUGAR</p>
                <p className="video-slider-item__details-subtitle">1:13</p>
              </div>
            </div>

            <Modal centered show={showModal4} onHide={handleClose4}>
              <Modal.Body>
                <ReactPlayer url={require('../../assets/video/EL_LUGAR.mp4')} controls loop width="100%" height="100%" config={{ file: { attributes: { controlsList:'nodownload' } }}}></ReactPlayer>
              </Modal.Body>
            </Modal>

            <div className="video-slider-item" onClick={handleShow5}>
              <div>
                <img className="video-slider-item__img" src={require('../../assets/img/la-historia_poster.png')}  width="100%" alt="Video"/>
              </div>

              <div className="video-slider-item__details">
                <p className="video-slider-item__details-title">LA HISTORIA</p>
                <p className="video-slider-item__details-subtitle">3:10</p>
              </div>
            </div>

            <Modal centered show={showModal5} onHide={handleClose5}>
              <Modal.Body>
                <ReactPlayer url={require('../../assets/video/LA_HISTORIA.mp4')} controls loop width="100%" height="100%" config={{ file: { attributes: { controlsList:'nodownload' } }}}></ReactPlayer>
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </div>
  );
}

export default VideoSlider;