import React, { useRef, useState  } from "react";
import './form.component.scss';
import emailjs from "@emailjs/browser";

//asistencia
//service id asistencia:service_uzn5i36
// public key: ulGmlUhJu9azPOiBh 
//template id: template_l3chvyk

const FormAsistencia = (props) => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();
    setMessageSent(true);
    emailjs
      .sendForm(
        "service_uzn5i36",
        "template_l3chvyk",
        form.current,
        "ulGmlUhJu9azPOiBh"
      )
      .then(
        (result) => {
          console.log("sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
      <form ref={form} onSubmit={sendEmail} className="form">
        <input className="form__input-family" type="text" name="familia" value={props.family} readOnly={true}/>

        <ul className="form__option-list">
        <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="si" id="si" value="si" />
            <label className="form__option-list-item-label">Sí, quiero</label>
        </li>
        <li className="form__option-list-item">
          <input className="form__option-list-item-checkbox" type="checkbox" name="no" id="no" value="no" />
          <label className="form__option-list-item-label">Lo siento, no podré</label>
        </li>
        </ul>
        {!messageSent ?
          <input type="submit" value="Confirmar" className="boton"/>
          : <p className={'form__message-sent form__message-sent--' + messageSent}>
              MUCHAS GRACIAS!
            </p>
        }
      </form>
  );
};





export default FormAsistencia;
