import React, { useRef, useState  } from "react";
import './form.component.scss';
import emailjs from "@emailjs/browser";

//canciones
//service id : service_no14u7l
// public key: 1mPpbryUceYDokA2Z
//template id: template_jwvpe2k

const FormBus = (props) => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);
    
    const sendEmail = (e) => {
      e.preventDefault();
      setMessageSent(true);
  
      emailjs
        .sendForm(
          "service_no14u7l",
          "template_jwvpe2k",
          form.current,
          "1mPpbryUceYDokA2Z"
        )
        .then(
          (result) => {
            console.log("sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
  
    return (
        <form ref={form} onSubmit={sendEmail} className="form">
          <input className="form__input-family"  type="text" name="familia" value={props.family} readOnly/>
          {!messageSent ?
            <input type="submit" value="ME APUNTO AL AUTOBÚS" className="boton"/>
            : <p className={'form__message-sent form__message-sent--' + messageSent}>
                CONFIRMADO!
              </p>
          }
        </form>
    );
  };
  
  export default FormBus;