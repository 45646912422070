import React, { useRef, useState} from "react";
import './form.component.scss';
import emailjs from "@emailjs/browser";

//canciones
//service id : service_5n3sfcb
// public key: I08zOIPjuVwhzt0F3
//template id: template_qym493s
//---------------------------------

const FormMusica = (props) => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);
  
    const sendEmail = (e) => {
      e.preventDefault();
      setMessageSent(true);
      emailjs
        .sendForm(
          "service_5n3sfcb",
          "template_qym493s",
          form.current,
          "I08zOIPjuVwhzt0F3"
        )
        .then(
          (result) => {
            console.log("sent");
            
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
  
    return (
        <form ref={form} onSubmit={sendEmail} className="form">
          <input className="form__input-family"  type="text" name="familia" value={props.family} readOnly/>
          <div className="form__input-wrapper">
            <input  className="form__input" type="text" name="canciones" id="canciones"/>
          </div>
          {!messageSent ?
            <input type="submit" value="Enviar" className="boton"/>
            : <p className={'form__message-sent form__message-sent--' + messageSent}>
                ¡GRACIAS POR COMPARTIR TUS CANCIONES CON NOSOTROS!
              </p>
          }
        </form>
    );
  };
  
  export default FormMusica;