import React, { useRef, useState  } from "react";
import emailjs from "@emailjs/browser";
import './form.component.scss';

//alergias: 
// public key: uRbNPQHBWGZz3wYgB
// service id: service_q9ov6yl
//template id: template_mylo17r

const FormComida = (props) => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
      setMessageSent(true);
       emailjs
         .sendForm(
           "service_q9ov6yl",
           "template_mylo17r",
           form.current,
           "uRbNPQHBWGZz3wYgB"
        )
        .then(
          (result) => {
            console.log("sent");

          },
         (error) => {

            console.log(error.text);
           }
       );
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="form">
          <input  className="form__input-family" type="text" name="familia" value={props.family} readOnly/>
  
            <ul className="form__option-list">
          <li className="form__option-list-item">
              <input className="form__option-list-item-checkbox" type="checkbox" name="gluten" id="gluten" value="gluten"/>
              <label className="form__option-list-item-label">Gluten</label>
          </li>
          <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="lactosa" id="lactosa" value="lactosa"/>
            <label className="form__option-list-item-label">Lactosa</label>
          </li>
          <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="huevo" id="huevo" value="huevo"/>
            <label className="form__option-list-item-label">Huevo</label>
          </li>
          <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="frutos_secos" id="frutos_secos" value="frutos secos"/>
            <label className="form__option-list-item-label">Frutos Secos</label>
          </li>
          <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="marisco" id="marisco" value="marisco"/>
            <label className="form__option-list-item-label">Marisco</label>
          </li>
          <li className="form__option-list-item">
            <input className="form__option-list-item-checkbox" type="checkbox" name="otros" id="otros" value="otros"/>
            <label className="form__option-list-item-label">Otros</label>
          
          </li>
          </ul>
          <div className="form__input-wrapper">
            <input  className="form__input" type="text" name="otros_texto" id="otros_texto"/>
          </div>
          {!messageSent ?
            <input type="submit" value="Enviar" className="boton boton--secondary"/>
            : <p className={'form__message-sent form__message-sent--' + messageSent}>
                ¡OÍDO COCINA!
              </p>
          }
        </form>
    );
  };
  
  export default FormComida;