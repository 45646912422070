
import './content.component.scss';
import  data from '../../sections.json';
import FormComida from '../forms/form-comida.component';
import FormBus from '../forms/form-bus.component';
import FormMusica from '../forms/form-musica.component';
import FormAsistencia from '../forms/form-asistencia.component';
import Slider from '../slider/slider.component';
import VideoSlider from '../video-slider/video-slider.component';
import { Fade } from "react-awesome-reveal";


function Content(props) {

  return (
    <div>
        {
          data.map((data,i)=>(

          <div key={i}>
             {/* <img className='main__portada' src={require("../../assets/img/fotografia_portada.jpg")} alt={"PaolayAlbert"}  /> */}

            <div className='main__portada'>
              
            </div>
            <div className="section main" >
                <div className="main__date-wrapper">
                  <p className="main__date">
                  {data.sections[0].main.date}
                  </p>
                </div>
                <h1 className="main__title">
                  {data.families[props.familyId].familia}
                </h1>
                <div className='section__content'>
                  <h3 className="section__subtitle">
                    {data.sections[0].main.subtitle}
                  </h3>
                  <img className='main__picture' src={require("../../assets/img/rings_beige.png")} alt={"PaolayAlbert"} width="110px" />
                  <p className="section__text">
                    {data.sections[0].main.text}
                  </p>
                
              </div>
            </div>
            
            <div className='general-information__wrapper'> 
              <div className="section general-information">
                <Fade>
                  <img className="general-information__img--up" alt={"PaolayAlbert"} src={require("../../assets/img/fotografia_can-ribas-1.jpg")} width="80%" />
                  <div className="section__content">
                    <h2 className="section__title">
                      {data.sections[1].ceremony.title}
                    </h2>
                    <img className='general-information__icon' src={require("../../assets/img/reloj_icono.png")} alt={"PaolayAlbert"} width="30px" />
                    <p className="section__subtitle">
                    <span> {data.sections[1].ceremony.time}</span>
                    </p>
                    <img className='general-information__icon' src={require("../../assets/img/ubicacion_icono.png")} alt={"PaolayAlbert"} width="30px" />
                    <p className="section__subtitle">
                      <span>CAN RIBAS DE MONTBUI</span>{data.sections[1].ceremony.location}
                    </p>
                  </div>
                  <p className="link">
                    <a href='https://maps.app.goo.gl/7298zp8yvxTQp3q68' target='blank'>
                      {data.sections[1].ceremony.buttonText}
                    </a>
                  </p>
                </Fade>
              </div>
            <Fade>
              <div className="section general-information ">
                <img className="general-information__img" src={require("../../assets/img/fotografia_can-ribas-2.jpg")} alt={"PaolayAlbert"} width="80%" />
                <div className="section__content">
                    <h3 className="section__title">
                        {data.sections[2].celebration.title}
                    </h3>
                    <p className="section__subtitle">
                        {data.sections[2].celebration.description}
                    </p>
                </div>
                <FormBus family={data.families[props.familyId].familia}></FormBus>
              </div>
            </Fade>
          </div>
          <Fade >
          <Slider extraPhotos={data.families[props.familyId].photos}></Slider>
          </Fade>
          <div className='section__wrapper-row'>
            <Fade >
              <div className="section confirmacion">
                <div className="section__content">
                  <h2 className="section__title">
                    {data.sections[3].confirmation.title}
                  </h2>
                  <p className="section__description">
                    {data.sections[3].confirmation.description}
                  </p>
                  <FormAsistencia family={data.families[props.familyId].familia} className="form"></FormAsistencia>
                </div>
              </div>
            </Fade>
            <Fade >
              <div className="section comida">
                <div className="section__content">
                  <h2 className="section__title">
                    {data.sections[4].food.title}
                  </h2>
                  <p className="section__description">
                    {data.sections[4].food.description}
                  </p>
                  <FormComida family={data.families[props.familyId].familia} className="form"></FormComida>
                </div>
              </div>
            </Fade>
          </div>
          <div className='section__wrapper-row'>
            <Fade >
              <div className="section vestimenta">
                <div className='section__img'>
                <img className="vestimenta__img" src={require("../../assets/img/fotografia_vestuario.png")} alt={"PaolayAlbert"} width="100%" />
                </div>
                <div className='section__content'>
                  <h2 className="section__title">
                    {data.sections[5].dressCode.title}
                  </h2>
                  <p className="section__description">
                    {data.sections[5].dressCode.description}
                  </p>
                </div>
              </div>
              </Fade>

              <Fade >
              <div className="section fiesta">
                <div className='section__img'>
                  <img className='fiesta__img' src={require("../../assets/img/slider/fotografia_fiesta.png")} alt={"PaolayAlbert"} width="100%" />
                </div>
                <div className="section__content">
                  <h2 className="section__title">
                    {data.sections[6].music.title}
                  </h2>
                  <p className="section__description">
                    {data.sections[6].music.description}
                  </p>
                  <FormMusica family={data.families[props.familyId].familia}></FormMusica>
                </div>
              </div> 
              </Fade>


            </div>
            <Fade >
            <div className="section videos">
              <VideoSlider></VideoSlider>
            </div> 
            </Fade>
          </div>
          ))
        }
    </div>

  );
}

export default Content;
