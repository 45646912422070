import './header.component.scss';

function Header() {
  return (
    <div className="header">
      <img className="header__img" src={require("../../assets/img/logotipo_terracota.png")}  alt={"Paola&Albert"}/>
    </div>
  );
}

export default Header;
